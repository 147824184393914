

.home-container {
    margin: 10px;
}

.swiper-slide  {
 display: flex;
/* width: 1000px; */
/* background-color: red; */
}

.swiper-content {
 width: 150px;
}

img {
    width: 100%;
}

h2 {
    padding-top: 20px;  
    line-height: 30px;  
    text-align: center;
    /* color:  #013770; */
    background-color: #013770;
    padding-top: 25px;
    padding-bottom: 25px;
}

.home-para {
    margin-top: 40px;
    margin-left: 10px;
    margin-right: 10px;
    line-height: 30px;
}