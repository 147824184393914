.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {    
  /* width: 99%;   */
  font-family: Arial, Helvetica, sans-serif;
  background-color: #588cc8;
  color: #e7e9f6;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.banner {
  height: 55px;
  align-items: center;   
  padding-left: 40px;
  font-weight: 700;
}

.name {
  position: relative;
  z-index: 2;  
  top: 3px;  
  font-size: 24px;
  margin-left: 15px;
}
.phone {
  position: relative;
  z-index: 2;
  float: right;
  top: 10px;
  margin-right: 10px;
  color: #e7e9f6;
  font-size: 24px;
  padding-bottom: 10px;
}

.logo {
  height: 54px;
  width: 93px;
}


.popup {
  background-color: #013770;
  width: 50%;
  padding: 25px;
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 60%;
  border-radius: 8px;
  font-family: "Poppins",sans-serif;
  display: block  ;
  text-align: center;
  z-index: 2;
  margin-top: 383px;
}
.popup button {
  display: block;
  margin:  0 0 20px auto;
  background-color: transparent;
  font-size: 30px;
  color: #c5c5c5;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 800px)  {
  .popup {
    width: 80%;
  }
  
  }
