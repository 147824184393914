
.footer-container {
    /* height: 150px; */
    background-color: #013770;
    margin: 0px;
    position: relative;
    left: 0;
    bottom: -150px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;    
}

h4 {
    font-weight: 700;        
}

.version {
    font-size: 14px;
}

ul {
    list-style-type: none;
}

.usefull {
    font-size: 18px;
}