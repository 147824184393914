.logo {
  height: 54px;
  width: 93px;
}

.navbar {
  width: 100%;
}

.navstyle {
  background-color: #011031;  
}

a.nav-link {
  color: #e7e9f6 !important;
  text-decoration: dashed !important;
}

.navbar-toggler {
  background-color: #588CC8
  !important;  
} 

