
.contact {
    margin: 20px;

}

.button {
    margin-top: 25px;
}

label {
    margin-top: 20px;
    margin-bottom: 5px !important;
}